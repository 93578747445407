import React from "react";
import styled from "styled-components";

import List from "../list";

const Container = styled.div`
  width: 100%;
  background-color: black;
  color: white;
  padding: ${props => props.theme.xsP};
`;

const Heading = styled.h2`
  margin-bottom: 48px;
  font-weight: 300;
`;

function SpecialOffer() {
  return (
    <Container id="special-offer">
      <Heading>Special Offer For Joining My Network</Heading>
      <List
        items={[
          {
            faClassName: "fas fa-check",
            headingText: "Free CV Review"
          },
          {
            faClassName: "fas fa-check",
            headingText: "Free LinkedIn Profile Review"
          },
          {
            faClassName: "fas fa-check",
            headingText: "Advice on Job Boards"
          }
        ]}
      />
    </Container>
  );
}

export default SpecialOffer;
