import React, { useContext, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";

import AuthContext from "../../context/auth/authContext";

import FormInput from "../../components/form-input";
import CustomButton from "../../components/custom-button";

const Container = styled.div`
  width: 100%;
  padding: 100px ${props => props.theme.xsP};
`;

const Heading = styled.h2`
  margin-bottom: 48px;
  font-weight: 300;
`;

const StyledLink = styled(Link)`
  display: block;
  margin-top: 20px;
`;

function SignInPage() {
  const authContext = useContext(AuthContext);

  const [formData, setFormData] = useState({
    email: "",
    password: ""
  });

  const history = useHistory();

  if (authContext.user) {
    if (authContext.user.role === "user") {
      history.push("/profile");
    } else if (authContext.user.role === "admin") {
      history.push("/users");
    }
  }

  function handleSubmit(event) {
    event.preventDefault();

    authContext.signIn(formData);
  }

  function handleChange(event) {
    const { name, value } = event.target;

    setFormData({ ...formData, [name]: value });
  }

  return (
    <Container>
      <Heading>Sign In To Update Your Jobseeker Profile</Heading>
      <form onSubmit={handleSubmit}>
        <FormInput
          label="Email"
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          autoComplete="email"
        />
        <FormInput
          label="Password"
          type="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          autoComplete="current-password"
        />
        <CustomButton type="submit">Sign in</CustomButton>
      </form>
      <StyledLink to="/">Don't have an account? Sign up</StyledLink>
    </Container>
  );
}

export default SignInPage;
