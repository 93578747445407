import React from "react";
import uuid from "uuid";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";

const subColor = "grey";
const mainColor = "black";

const shrinkLabelStyles = css`
  top: -14px;
  font-size: 12px;
  color: ${mainColor};
`;

const Container = styled.div`
  position: relative;
  margin: 45px 0;
  input[type="password"] {
    letter-spacing: 0.3em;
  }
  &:first-child {
    margin-top: 0;
  }
`;

const Input = styled.input`
  background: none;
  background-color: white;
  color: ${subColor};
  font-size: 18px;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid ${subColor};
  margin: 25px 0;
  &:focus {
    outline: none;
  }
  &:focus ~ label {
    ${shrinkLabelStyles}
  }
`;

const Select = styled.select`
  background: none;
  background-color: white;
  color: ${subColor};
  font-size: 18px;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid ${subColor};
  margin: 25px 0;
  &:focus {
    outline: none;
  }
  &:focus ~ label {
    ${shrinkLabelStyles}
  }
`;

const Label = styled.label`
  color: ${subColor};
  font-size: 16px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 300ms ease all;
  &.shrink {
    ${shrinkLabelStyles}
  }
`;

function FormInput({ label, options, isMultiple, ...props }) {
  function renderOption(option) {
    return (
      <option key={uuid.v4()} value={option.value}>
        {option.label}
      </option>
    );
  }

  if (options && !isMultiple) {
    options.unshift({ value: "", label: "Select" });
  }

  const inputMarkup = options ? (
    <Select multiple={isMultiple} {...props}>
      {options.map(renderOption)}
    </Select>
  ) : (
    <Input {...props} />
  );

  return (
    <Container>
      {inputMarkup}
      <Label
        className={
          (props.value && props.value.length) ||
          Array.isArray(props.value) ||
          typeof props.value === "number" ||
          props.checked === true ||
          props.checked === false
            ? "shrink"
            : null
        }
      >
        {label}
      </Label>
    </Container>
  );
}

FormInput.propTypes = {
  label: PropTypes.string.isRequired
};

export default FormInput;
