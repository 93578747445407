import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
import PropTypes from "prop-types";

import DarkOverlay from "../dark-overlay";
import HeroContent from "../hero-content";

const Container = styled.div`
  height: 100vh;
  width: 100%;
  background-image: url(${props => props.backgroundImageUrl});
  background-size: cover;
  background-position: bottom;
  position: relative;
  cursor: pointer;
`;

const Heading = styled.h2`
  font-size: 32px;
  color: white;
  font-weight: 300;
  text-shadow: ${props => props.theme.textShadow};
  max-width: 600px;
`;

const Paragraph = styled.p`
  color: white;
  text-shadow: ${props => props.theme.textShadow};
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 44px;
  max-width: 600px;
`;

const CustomLink = styled.span`
  border: 2px solid white;
  border-radius: 22px;
  color: ${props => (props.mouseIsOver ? "black" : "white")};
  font-weight: 300;
  height: 44px;
  width: 112px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props => (props.mouseIsOver ? "white" : "none")};
  transition: all 0.2s ease-in-out;
`;

function Hero3({
  backgroundImageUrl,
  headingText,
  paragraphText,
  buttonText,
  linkDest,
  ...props
}) {
  const [mouseIsOver, setMouseIsOver] = useState(false);

  function handleClick() {
    console.log("Click");
  }

  function handleMouseEnter() {
    setMouseIsOver(true);
  }

  function handleMouseLeave() {
    setMouseIsOver(false);
  }

  return (
    <Link
      to={linkDest}
      smooth
      offset={-70}
      duration={500}
      {...props}
      style={{ width: "100%" }}
    >
      <Container
        backgroundImageUrl={backgroundImageUrl}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <DarkOverlay opacity={mouseIsOver ? 0.8 : 0.6}>
          <HeroContent>
            <Heading className="mb-6">{headingText}</Heading>
            <Paragraph>{paragraphText}</Paragraph>
            <CustomLink href="#!" mouseIsOver={mouseIsOver}>
              {buttonText}
            </CustomLink>
          </HeroContent>
        </DarkOverlay>
      </Container>
    </Link>
  );
}

Hero3.propTypes = {
  backgroundImageUrl: PropTypes.string.isRequired,
  headingText: PropTypes.string.isRequired,
  paragraphText: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  linkDest: PropTypes.string
};

export default Hero3;
