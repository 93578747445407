import React from "react";
import styled from "styled-components";

const Th = styled.th`
  padding: 15px;
  background-color: ${props => props.theme.color1};
  font-size: 14px;
  font-weight: 400;
  color: ${props => props.theme.color6};
  text-transform: uppercase;
  letter-spacing: 0.8px;
`;

const Td = styled.td`
  border-top: ${props =>
    `${props.isFirstRow ? "2px" : "1px"} solid ${props.theme.color2}`};
  padding: 15px;
  color: ${props => props.theme.color8};
`;

function Cell({ content, isHeader, isFirstRow }) {
  const cellMarkup = isHeader ? (
    <Th>{content}</Th>
  ) : (
    <Td isFirstRow={isFirstRow}>{content}</Td>
  );

  return cellMarkup;
}

export default Cell;
