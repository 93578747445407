import React, { useContext } from "react";
import styled from "styled-components";

import AlertContext from "../../context/alert/alertContext";

const AlertsContainer = styled.div`
position: fixed;
top: 0;
left: 0;
width: 100%;
z-index: 200;
}
`;

const Container = styled.div`
    margin-bottom: 4px;
    padding: 12px ${props => props.theme.xsP};
    background-color: ${props =>
      props.type === "danger" ? "#dc3545" : "#28a745"};
    color: white;
  }
`;

const Alerts = () => {
  const alertContext = useContext(AlertContext);

  return (
    <AlertsContainer>
      {alertContext.alerts.length > 0 &&
        alertContext.alerts.map(alert => (
          <Container key={alert.id} type={alert.type}>
            <i className="fas fa-info-circle" /> {alert.msg}
          </Container>
        ))}
    </AlertsContainer>
  );
};

export default Alerts;
