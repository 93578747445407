import React, { useReducer } from "react";
import axios from "axios";

import usersReducer from "./usersReducer";
import { GET_USERS, ERROR_WITH_MESSAGE, CLEAR_ERRORS } from "../types";
import UsersContext from "./usersContext";

function UsersState({ children }) {
  const initialState = { users: [], errors: [] };

  const [state, dispatch] = useReducer(usersReducer, initialState);

  async function getUsers(queryString) {
    const url = queryString ? `/api/v1/users?${queryString}` : "/api/v1/users";

    try {
      const res = await axios(url);

      let users;

      if (!Array.isArray(res.data)) {
        users = [];
      } else {
        users = res.data;
      }

      dispatch({ type: GET_USERS, payload: users });
    } catch (err) {
      const errors = err.response.data.error.split(",");

      dispatch({ type: ERROR_WITH_MESSAGE, payload: errors });
    }
  }

  const clearErrors = () => dispatch({ type: CLEAR_ERRORS });

  return (
    <UsersContext.Provider
      value={{
        users: state.users,
        errors: state.errors,
        clearErrors,
        getUsers
      }}
    >
      {children}
    </UsersContext.Provider>
  );
}

export default UsersState;
