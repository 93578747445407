import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";

import AuthContext from "../../context/auth/authContext";
import AlertContext from "../../context/alert/alertContext";

import FormInput from "../form-input";
import CustomButton from "../custom-button";

const Container = styled.div`
  width: 100%;
  padding: ${props => props.theme.xsP};
`;

const Heading = styled.h2`
  margin-bottom: 48px;
  font-weight: 300;
`;

const StyledLink = styled(Link)`
  display: block;
  margin-top: 20px;
`;

function SignUp() {
  const authContext = useContext(AuthContext);
  const alertContext = useContext(AlertContext);

  const [agreed, setAgreed] = useState(false);
  const [userDidSubmitForm, setUserDidSubmitForm] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: ""
  });

  const history = useHistory();

  useEffect(() => {
    if (authContext.user && userDidSubmitForm) {
      history.push("/profile");
    }

    // eslint-disable-next-line
  }, [userDidSubmitForm, authContext.user]);

  async function handleSubmit(event) {
    event.preventDefault();

    if (formData.password !== formData.confirmPassword) {
      alertContext.setAlert("Passwords do not match", "danger");
    } else if (!agreed) {
      alertContext.setAlert("You must agree to our privacy policy to sign up", "danger");
    } else {
      await authContext.signUp(formData);
      setUserDidSubmitForm(true);
    }
  }

  function handleChange(event) {
    const { name, value } = event.target;

    setFormData({ ...formData, [name]: value });
  }

  function handleAgreedChange(event) {
    setAgreed(event.target.checked)
  }

  return (
    <Container>
      <Heading>Sign Up To Join My Network</Heading>
      <form onSubmit={handleSubmit}>
        <FormInput
          label="Name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          autoComplete="name"
        />
        <FormInput
          label="Email"
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          autoComplete="email"
        />
        <FormInput
          label="Password"
          type="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          autoComplete="new-password"
        />
        <FormInput
          label="Confirm password"
          type="password"
          name="confirmPassword"
          value={formData.confirmPassword}
          onChange={handleChange}
          autoComplete="new-password"
        />
        <a style={{ color: "dodgerblue"}} href="https://www.FreePrivacyPolicy.com/privacy/view/ffdd4abb253632da3d022135f6a6de57" target="_black">Privacy Policy</a>
        <FormInput
            label="I have read and agree to the privacy policy above"
            type="checkbox"
            checked={agreed}
            onChange={handleAgreedChange}
        />
        <CustomButton ntype="submit" isBlock>
          Sign up
        </CustomButton>
      </form>
      <StyledLink to="/sign-in">
        Already have an account? Sign in to update your jobseeker profile
      </StyledLink>
    </Container>
  );
}

export default SignUp;
