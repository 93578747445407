import React from "react";
import styled from "styled-components";
// import { Link } from "react-scroll";

import DarkOverlay from "../dark-overlay";
import HeroContent from "../hero-content";
import Arrow from "../../components/arrow";

import background1 from "../../assets/background1.jpg";
// import logo from "../../assets/logo.svg";

const Container = styled.div`
  height: 100vh;
  background-image: url(${background1});
  background-size: cover;
  background-position: bottom;
  position: relative;
`;

const Heading = styled.h1`
  font-size: 52px;
  margin-bottom: 44px;
  color: white;
  text-shadow: ${props => props.theme.textShadow};
  max-width: 600px;

  @media (min-width: 768px) {
    font-size: 64px;
  }
`;

// const StyledLink = styled(Link)`
//   letter-spacing: 6px;
//   text-transform: uppercase;
//   font-size: 14px;
//   font-weight: 300;
//   color: white;
//   text-shadow: ${props => props.theme.textShadow};
//   cursor: pointer;
// `;

function Hero1() {
  return (
    <Container>
      <DarkOverlay>
        <HeroContent>
          {/* <img src={logo} alt="logo" style={{ maxWidth: "100%" }} /> */}
          <Heading>McLaren Raeside Recruitment</Heading>
          {/* <StyledLink to="hero2" smooth>
            Learn More &rarr;
          </StyledLink> */}
          <Arrow />
        </HeroContent>
      </DarkOverlay>
    </Container>
  );
}

export default Hero1;
