import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import DarkOverlay from "../dark-overlay";
import Feature from "./Feature";

import background4 from "../../assets/background4.jpg";

const Container = styled.div`
  background-image: url(${background4});
  background-size: cover;
  background-position: bottom;
  position: relative;
`;

const Grid = styled.div`
  padding: ${props => props.theme.xsP};
  display: grid;
  justify-items: start;
  grid-row-gap: 36px;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 992px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

function MultiFeature({ features }) {
  const featuresMarkup = features.map((feature, index) => (
    <Feature key={index} {...feature} />
  ));

  return (
    <Container>
      <DarkOverlay opacity={0.8}>
        <Grid>{featuresMarkup}</Grid>
      </DarkOverlay>
    </Container>
  );
}

MultiFeature.propTypes = {
  features: PropTypes.arrayOf(
    PropTypes.shape({
      faClassName: PropTypes.string,
      headingText: PropTypes.string,
      paragraphText: PropTypes.string
    })
  )
};

export default MultiFeature;
