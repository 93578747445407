import React from "react";
import styled from "styled-components";

import CustomFaIcon from "../custom-fa-icon";

const Container = styled.li`
  padding-left: 16px;
  margin-bottom: 28px;
  font-weight: 300;
`;

const Heading = styled.h3`
  margin-bottom: 8px;
  font-weight: 300;
`;

function Item({ faClassName, headingText }) {
  return (
    <Container>
      <span className="fa-li">
        <CustomFaIcon faClassName={faClassName} />
      </span>
      <Heading>{headingText}</Heading>
    </Container>
  );
}

export default Item;
