import React from "react";
import styled from "styled-components";
import uuid from "uuid";
import PropTypes from "prop-types";

import Cell from "./Cell";

const Container = styled.div`
  border-radius: ${props => props.theme.borderRadius};
  max-width: 100vw;
  background-color: white;
  box-shadow: ${props => props.theme.boxShadow4};
  overflow: hidden;
`;

const ScrollContainer = styled.div`
  overflow-x: auto;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  text-align: left;
`;

const TableRow = styled.tr`
  &:hover {
    background-color: ${props => props.theme.color1};
  }
`;

function DataTable({ headings, rows }) {
  function renderHeading(cell) {
    return <Cell key={uuid.v4()} content={cell} isHeader />;
  }

  function renderRow(row, index) {
    return (
      <TableRow key={uuid.v4()}>
        {row.map(cell => (
          <Cell key={uuid.v4()} content={cell} isFirstRow={index === 0} />
        ))}
      </TableRow>
    );
  }

  const theadMarkup = <tr>{headings.map(renderHeading)}</tr>;

  const tbodyMarkup = rows.map(renderRow);

  return (
    <Container>
      <ScrollContainer>
        <Table>
          <thead>{theadMarkup}</thead>
          <tbody>{tbodyMarkup}</tbody>
        </Table>
      </ScrollContainer>
    </Container>
  );
}

DataTable.defaultProps = {
  headings: ["Heading 1", "Heading 2"],
  rows: [
    ["Some data", "Some data"],
    ["Some data", "Some data"]
  ]
};

DataTable.propTypes = {
  headings: PropTypes.array,
  rows: PropTypes.array
};

export default DataTable;
