import React from "react";
import styled, { keyframes } from "styled-components";
// import { Link } from "react-scroll";

const bounce = keyframes`
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }

    40% {
      transform: translateY(-30px);
    }

    60% {
      transform: translateY(-15px);
    }
`;

const Container = styled.span`
  width: 50px;
  padding: 24px 0 10px 0;
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-25px);
`;

const Icon = styled.i`
  animation: ${bounce} 2s infinite;
  color: white;
`;

function Arrow() {
  return (
    <Container>
      <Icon className="fas fa-chevron-down fa-lg" />
    </Container>
  );
}

export default Arrow;
