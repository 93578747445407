import React from "react";
import styled, { css } from "styled-components";

const buttonStyles = css`
  background-color: black;
  color: white;
  border: none;

  &:hover {
    background-color: white;
    color: black;
    border: 1px solid black;
  }
`;

const invertedButtonStyles = css`
  background-color: white;
  color: black;
  border: 1px solid black;

  &:hover {
    background-color: black;
    color: white;
    border: none;
  }
`;

const getButtonStyles = props =>
  props.inverted ? invertedButtonStyles : buttonStyles;

const Container = styled.button`
  min-width: 165px;
  width: ${props => (props.isBlock ? "100%" : "auto")};
  height: 50px;
  letter-spacing: 0.5px;
  line-height: 50px;
  padding: 0 35px 0 35px;
  font-size: 15px;
  text-transform: uppercase;
  font-family: ${props => props.theme.fontStack};
  font-weight: bolder;
  cursor: pointer;
  display: flex;
  justify-content: center;
  ${getButtonStyles}
`;

function CustomButton({ children, isBlock, ...props }) {
  return (
    <Container isBlock={isBlock} {...props}>
      {children}
    </Container>
  );
}

export default CustomButton;
