import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";

import AlertContext from "../../context/alert/alertContext";

import FormInput from "../../components/form-input";
import CustomButton from "../../components/custom-button";

const Container = styled.main`
  padding: 100px ${props => props.theme.xsP};
`;

const Form = styled.form`
  padding: 20px;
  background-color: white;
  box-shadow: ${props => props.theme.boxShadow4};
`;

const Section = styled.div`
  :not(:last-of-type) {
    margin-bottom: 48px;
  }
`;

const Heading = styled.h2`
  margin-bottom: 28px;
  font-weight: 400;
`;

const ARRAY_PROPS = [
  "sectors",
  "softwarePackages",
  "accreditations",
  "methodologies",
  "associations"
];

function ProfilePage() {
  const alertContext = useContext(AlertContext);

  const [isCreate, setIsCreate] = useState(false);
  const [formData, setFormData] = useState({
    lookingForWork: true,
    name: "",
    phone: "",
    email: "",
    homePostcode: "",
    securityClearanceLevel: "",
    currentJobTitle: "",
    sectors: "",
    softwarePackages: "",
    accreditations: "",
    methodologies: "",
    associations: "",
    desiredJobTitle: "",
    jobTypes: [],
    maxCommuteDistance: 0,
    willRelocate: false
  });
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    async function getProfile() {
      const res = await axios("/api/v1/profile");

      if (res.data) {
        const { data } = res;

        const profile = {};

        for (const key in data) {
          if (key === "user") {
            const { name, email } = data.user;

            profile.name = name;
            profile.email = email;
          } else if (ARRAY_PROPS.includes(key)) {
            profile[key] = data[key].join(",");
          } else if (data[key]) {
            profile[key] = data[key];
          }
        }

        setFormData({ ...formData, ...profile });
      } else {
        const res = await axios("/api/v1/auth/me");

        const { name, email } = res.data;

        setIsCreate(true);
        setFormData({ ...formData, name, email });
      }
    }

    getProfile();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    errors.forEach(err => alertContext.setAlert(err, "danger"));

    setErrors([]);

    // eslint-disable-next-line
  }, [errors.length]);

  async function handleSubmit(event) {
    event.preventDefault();

    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    const body = { ...formData };

    for (const key in body) {
      if (!body[key] || (Array.isArray(body[key]) && body[key].length === 0)) {
        delete body[key];
      }
    }

    ARRAY_PROPS.forEach(key => {
      if (body[key]) body[key] = body[key].split(",").map(item => item.trim());
    });

    try {
      await axios.put("/api/v1/auth/update-details", body, config);
      await axios[isCreate ? "post" : "put"]("/api/v1/profile", body, config);

      if (isCreate) setIsCreate(false);

      alertContext.setAlert("Saved", "success");
    } catch (err) {
      const errors = err.response.data.error.split(",");
      setErrors(errors);
    }
  }

  function handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setFormData({ ...formData, [name]: value });
  }

  function handleSelectMultipleChange(event) {
    const { name, selectedOptions } = event.target;

    const value = Array.from(selectedOptions, option => option.value);

    setFormData({ ...formData, [name]: value });
  }

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Section>
          <Heading>My details</Heading>
          <FormInput
            label="Looking for work"
            type="checkbox"
            name="lookingForWork"
            checked={formData.lookingForWork}
            onChange={handleInputChange}
          />
          <FormInput
            label="Name"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
          />
          <FormInput
            label="Phone"
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleInputChange}
          />
          <FormInput
            label="Email"
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
          />
          <FormInput
            label="Home postcode"
            name="homePostcode"
            value={formData.homePostcode}
            onChange={handleInputChange}
          />
          <FormInput
            label="Security clearance level"
            name="securityClearanceLevel"
            value={formData.securityClearanceLevel}
            onChange={handleInputChange}
            options={[
              { value: "ctc", label: "CTC" },
              { value: "sc", label: "SC" },
              { value: "dv", label: "DV" },
              { value: "enhanced-dv", label: "Enhanced DV" }
            ]}
          />
        </Section>
        <Section>
          <Heading>Work experience</Heading>
          <FormInput
            label="Current job title"
            name="currentJobTitle"
            value={formData.currentJobTitle}
            onChange={handleInputChange}
          />
          <FormInput
            label="Sectors (comma separated list)"
            name="sectors"
            value={formData.sectors}
            onChange={handleInputChange}
          />
          <FormInput
            label="Software packages (comma separated list)"
            name="softwarePackages"
            value={formData.softwarePackages}
            onChange={handleInputChange}
          />
          <FormInput
            label="Accreditations (comma separated list)"
            name="accreditations"
            value={formData.accreditations}
            onChange={handleInputChange}
          />
          <FormInput
            label="Methodologies (comma separated list)"
            name="methodologies"
            value={formData.methodologies}
            onChange={handleInputChange}
          />
          <FormInput
            label="Associations (comma separated list)"
            name="associations"
            value={formData.associations}
            onChange={handleInputChange}
          />
        </Section>
        <Section>
          <Heading>Your next move</Heading>
          <FormInput
            label="Desired job title"
            name="desiredJobTitle"
            value={formData.desiredJobTitle}
            onChange={handleInputChange}
          />
          <FormInput
            label="Job types"
            name="jobTypes"
            value={formData.jobTypes}
            onChange={handleSelectMultipleChange}
            options={[
              { value: "contract", label: "Contract" },
              { value: "permanent", label: "Permanent" },
              { value: "temporary", label: "Temporary" }
            ]}
            isMultiple
          />
          <FormInput
            label="Maximum commute distance"
            type="number"
            name="maxCommuteDistance"
            value={formData.maxCommuteDistance}
            onChange={handleInputChange}
          />
          <FormInput
            label="Will relocate"
            type="checkbox"
            name="willRelocate"
            checked={formData.willRelocate}
            onChange={handleInputChange}
          />
        </Section>
        <CustomButton type="submit">Save my profile</CustomButton>
      </Form>
    </Container>
  );
}

export default ProfilePage;
