import React, { useContext, useEffect } from "react";
import { Switch, Route } from "react-router-dom";

import AlertContext from "./context/alert/alertContext";
import AuthContext from "./context/auth/authContext";
import UsersContext from "./context/users/usersContext";

import Header from "./components/header";
import Alerts from "./components/alerts";
import PrivateRoute from "./components/private-route";

import HomePage from "./pages/home";
import SignInPage from "./pages/sign-in";
import ProfilePage from "./pages/profile";
import UsersPage from "./pages/users";

function App() {
  const alertContext = useContext(AlertContext);
  const authContext = useContext(AuthContext);
  const usersContext = useContext(UsersContext);

  useEffect(() => {
    authContext.errors.forEach(error => alertContext.setAlert(error, "danger"));

    authContext.clearErrors();

    // eslint-disable-next-line
  }, [authContext.errors.length]);

  useEffect(() => {
    usersContext.errors.forEach(error =>
      alertContext.setAlert(error, "danger")
    );

    usersContext.clearErrors();

    // eslint-disable-next-line
  }, [usersContext.errors.length]);

  return (
    <>
      <Header />
      <Alerts />
      <Switch>
        <Route exact path="/">
          <HomePage />
        </Route>
        <Route exact path="/sign-in">
          <SignInPage />
        </Route>
        <PrivateRoute roles={["user"]} exact path="/profile">
          <ProfilePage />
        </PrivateRoute>
        <PrivateRoute roles={["admin"]} exact path="/users">
          <UsersPage />
        </PrivateRoute>
      </Switch>
    </>
  );
}

export default App;
