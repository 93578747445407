import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: ${props => props.theme.xsP};
  text-align: center;
`;

function HeroContent({ children }) {
  return <Container>{children}</Container>;
}

export default HeroContent;
