import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";

import AuthContext from "../../context/auth/authContext";

function PrivateRoute({ roles, children, ...props }) {
  const authContext = useContext(AuthContext);

  const { user, isLoading } = authContext;

  return (
    <Route
      {...props}
      render={() =>
        !user && !isLoading ? (
          <Redirect to="/" />
        ) : user && !roles.includes(user.role) ? (
          <Redirect to="/" />
        ) : (
          children
        )
      }
    />
  );
}

export default PrivateRoute;
