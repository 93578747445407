import {
  AUTHENTICATE_USER,
  GET_ME,
  DEAUTHENTICATE_USER,
  AUTH_ERROR,
  ERROR_WITH_MESSAGE,
  CLEAR_ERRORS
} from "../types";

export default function(state, action) {
  const { type, payload } = action;

  switch (type) {
    case AUTHENTICATE_USER:
      localStorage.setItem("token", payload);
      return { ...state, token: payload, isLoading: false };
    case GET_ME:
      return { ...state, user: payload, isLoading: false };
    case DEAUTHENTICATE_USER:
    case AUTH_ERROR:
      localStorage.removeItem("token");
      return { ...state, token: null, user: null, isLoading: false };
    case ERROR_WITH_MESSAGE:
      return { ...state, errors: payload };
    case CLEAR_ERRORS:
      return { ...state, errors: [] };
    default:
      return state;
  }
}
