import React, { useContext } from "react";
import styled from "styled-components";

import UsersContext from "../../context/users/usersContext";

import Form from "./Form";
import DataTable from "../../components/data-table";

const Container = styled.main`
  padding: 100px ${props => props.theme.xsP};
`;

const Link = styled.a`
  color: dodgerblue;
  text-decoration: none;
`;

function UsersPage() {
  const usersContext = useContext(UsersContext);

  const rows = usersContext.users.map(user => {
    const row = [];

    for (const key in headingsDict) {
      if (key === "name") {
        row.push(user.user.name);
      } else if (key === "phone") {
        row.push(<Link href={`tel:${user.phone}`}>{user.phone}</Link>);
      } else if (key === "email") {
        row.push(
          <Link href={`mailto:${user.user.email}`}>{user.user.email}</Link>
        );
      } else if (Array.isArray(user[key])) {
        row.push(user[key].join(", "));
      } else if (typeof user[key] === "boolean") {
        row.push(user[key] ? "Yes" : "No");
      } else if (user[key]) {
        row.push(user[key]);
      } else {
        row.push("");
      }
    }

    return row;
  });

  return (
    <Container>
      <Form />
      <DataTable headings={headings} rows={rows} />
    </Container>
  );
}

const headingsDict = {
  name: "Name",
  phone: "Phone",
  email: "Email",
  homePostcode: "Home postcode",
  currentJobTitle: "Current job title",
  sectors: "Sectors",
  softwarePackages: "Software packages",
  accreditations: "Accreditations",
  methodologies: "Methodologies",
  associations: "Associations",
  desiredJobTitle: "Desired job title",
  jobTypes: "Job types",
  maxCommuteDistance: "Maximum commute distance",
  willRelocate: "Will relocate"
};

const headings = [];

for (const key in headingsDict) {
  headings.push(headingsDict[key]);
}

export default UsersPage;
