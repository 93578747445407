import React from "react";
import styled from "styled-components";

import Hero1 from "../../components/hero1";
// import Hero2 from "../../components/hero2";
import Hero3 from "../../components/hero3";
import SignUp from "../../components/sign-up";
import MultiFeature from "../../components/multi-feature";
import Contact from "../../components/contact";

import background2 from "../../assets/background2.jpg";
import background3 from "../../assets/background3.jpg";
import SpecialOffer from "../../components/special-offer";

const TwoPaneContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

function HomePage() {
  return (
    <div>
      <Hero1 />
      <TwoPaneContainer>
        <Hero3
          backgroundImageUrl={background2}
          headingText="Workforce Planning"
          paragraphText="I have worked with many organisations assisting with workforce planning on business change and major implementation projects. Whether you are looking for an individual hire or building a team, workforce planning is essential to your business and its continued success."
          buttonText="Get in Touch"
          linkDest="contact"
        />
        <Hero3
          backgroundImageUrl={background3}
          headingText="CV Writing"
          paragraphText="I have screened hundreds of CV’s during my time as a consultant. With less than 30 seconds to grab someones attention, your CV needs to get you noticed and stand out from the crowd. I offer consultations and a CV writing service that will help you on your way."
          buttonText="Get Started"
          linkDest="special-offer"
        />
      </TwoPaneContainer>
      <TwoPaneContainer>
        <SpecialOffer />
        <SignUp />
      </TwoPaneContainer>
      <MultiFeature
        features={[
          {
            faClassName: "fas fa-people-carry",
            headingText: "Permanent Recruitment",
            paragraphText:
              "I offer a permanent recruitment solution for individual hires or the building of teams."
          },
          {
            faClassName: "fas fa-file-contract",
            headingText: "Temporary and Contract Recruitment",
            paragraphText:
              "Temporary or contract recruitment can again be on an individual basis or to source a specialist team to complete a particular project."
          },
          {
            faClassName: "fas fa-lock",
            headingText: "Fixed Term Contract",
            paragraphText:
              "I often work with organisations on a fixed term contract to help them through a particular project, peak periods or consult on recruitment needs."
          },
          {
            faClassName: "fas fa-globe-europe",
            headingText: "Diversity and Inclusion",
            paragraphText:
              "I lead on diversity and inclusion strategies and help organisations align their internal strategies to their workforce balance."
          },
          {
            faClassName: "fas fa-bullhorn",
            headingText: "Brand Management",
            paragraphText:
              "When I am engaged with an organisation I am the voice and face of the company and my marketing activity is tailored to reflect your brand."
          },
          {
            faClassName: "fas fa-code",
            headingText: "Specialist Areas",
            paragraphText:
              "I specialise in the recruitment of professionals both permanent and contract hires. The fields I have particular expertise in are IT, Finance, Marketing, Procurement and HR."
          }
        ]}
      />
      <Contact />
    </div>
  );
}

export default HomePage;
