import React from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import { Link, NavLink } from "react-router-dom";

const Container = styled.nav`
  width: 300px;
  height: 100%;
  background-color: white;
  visibility: ${props => (props.showNav ? "visible" : "hidden")};
  transform: translateX(${props => (props.showNav ? "0" : "-100%")});
  transition: all 0.2s ease-out;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  box-shadow: ${props => props.theme.boxShadow2};
`;

const linkStyles = css`
  background-color: ${props => props.theme.color9};
  color: white;
  display: block;
  padding: 16px 0 16px 24px;
  font-size: 20px;
`;

const StyledLink = styled(Link)`
  ${linkStyles}
`;

const AuthGreeting = styled.span`
  ${linkStyles}
`;

const List = styled.ul`
  list-style: none;
`;

const StyledNavLink = styled(NavLink)`
  padding: 16px 0 16px 24px;
  text-decoration: none;
  display: block;
  font-weight: 300;

  &:hover {
    background-color: black;
    color: white;
  }
`;

const DarkUnderlay = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, ${props => (props.showNav ? "0.8" : "0")});
  visibility: ${props => (props.showNav ? "visible" : "hidden")};
  transition: all 0.2s ease-out;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
`;

function Nav({ showNav, dismiss, user, signOut }) {
  function handleSignOutClick() {
    signOut();
    dismiss();
  }

  return (
    <>
      <Container showNav={showNav}>
        {user ? (
          <AuthGreeting>
            {" "}
            <i className="fas fa-user" style={{ marginRight: "12px" }} />
            Hello {user.name.split(" ")[0]}
          </AuthGreeting>
        ) : (
          <StyledLink to="/sign-in" onClick={dismiss}>
            <i className="fas fa-user" style={{ marginRight: "12px" }} />
            Hello, Sign in
          </StyledLink>
        )}
        <List>
          <li>
            <StyledNavLink exact to="/" onClick={dismiss}>
              Home
            </StyledNavLink>
          </li>
          {user && user.role === "user" && (
            <li>
              <StyledNavLink exact to="/profile" onClick={dismiss}>
                My Jobseeker Profile
              </StyledNavLink>
            </li>
          )}
          {user && user.role === "admin" && (
            <li>
              <StyledNavLink exact to="/users" onClick={dismiss}>
                Candidate Directory
              </StyledNavLink>
            </li>
          )}
          {user && (
            <li>
              <StyledNavLink exact to="#!" onClick={handleSignOutClick}>
                Sign out &rarr;
              </StyledNavLink>
            </li>
          )}
        </List>
      </Container>
      <DarkUnderlay showNav={showNav} onClick={dismiss} />
    </>
  );
}

Nav.propTypes = {
  showNav: PropTypes.bool.isRequired,
  dismiss: PropTypes.func.isRequired
};

export default Nav;
