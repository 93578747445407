import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: hsla(0, 0%, 0%, ${props => props.opacity});
  postion: absolute;
  top: 0;
  left: 0;
  transition: background-color 0.2s ease-in-out;
`;

function DarkOverlay({ opacity, children }) {
  return <Container opacity={opacity}>{children}</Container>;
}

DarkOverlay.defaultProps = {
  opacity: 0.6
};

DarkOverlay.propTypes = {
  opacity: PropTypes.number
};

export default DarkOverlay;
