import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";

import UsersContext from "../../context/users/usersContext";

import FormInput from "../../components/form-input";
import CustomButton from "../../components/custom-button";

const Container = styled.form`
  margin-bottom: 48px;
  padding: 20px;
  background-color: white;
  box-shadow: ${props => props.theme.boxShadow2};
`;

const Heading = styled.h2`
  margin-bottom: 28px;
  font-weight: 400;
`;

const ARRAY_PROPS = [
  "sectors",
  "softwarePackages",
  "accreditations",
  "methodologies",
  "associations"
];

function Form() {
  const usersContext = useContext(UsersContext);

  const [formData, setFormData] = useState({
    jobTitle: "",
    jobTypes: [],
    jobPostcode: "",
    sectors: "",
    softwarePackages: "",
    accreditations: "",
    methodologies: "",
    associations: ""
  });

  useEffect(() => {
    usersContext.getUsers();

    // eslint-disable-next-line
  }, []);

  function handleSubmit(event) {
    event.preventDefault();

    const query = [];

    for (const key in formData) {
      let value = formData[key];

      if (ARRAY_PROPS.indexOf(key) > -1) {
        const arr = value.split(",").map(item => item.trim().toLowerCase());

        if (arr[0] === "") arr.shift();

        value = arr;
      }

      if (value) {
        if (Array.isArray(value) && value.length) {
          value.forEach(item =>
            query.push(`${key}[all]=${item.toLowerCase()}`)
          );
        } else if (!Array.isArray(value)) {
          query.push(`${key}=${value.toLowerCase()}`);
        }
      }
    }

    const queryString = query.join("&");

    usersContext.getUsers(queryString);
  }

  function handleInputChange(event) {
    const { name, value } = event.target;

    setFormData({ ...formData, [name]: value });
  }

  function handleSelectMultipleChange(event) {
    const { name, selectedOptions } = event.target;

    const value = Array.from(selectedOptions, option => option.value);

    setFormData({ ...formData, [name]: value });
  }

  return (
    <Container onSubmit={handleSubmit}>
      <Heading>Job Description</Heading>
      <FormInput
        label="Job title"
        name="jobTitle"
        value={formData.jobTitle}
        onChange={handleInputChange}
      />
      <FormInput
        label="Job types"
        name="jobTypes"
        value={formData.jobTypes}
        onChange={handleSelectMultipleChange}
        options={[
          { value: "contract", label: "Contract" },
          { value: "permanent", label: "Permanent" },
          { value: "temporary", label: "Temporary" }
        ]}
        isMultiple
      />
      <FormInput
        label="Job postcode"
        name="jobPostcode"
        value={formData.jobPostcode}
        onChange={handleInputChange}
      />
      <FormInput
        label="Sectors (comma separated list)"
        name="sectors"
        value={formData.sectors}
        onChange={handleInputChange}
      />
      <FormInput
        label="Software packages (comma separated list)"
        name="softwarePackages"
        value={formData.softwarePackages}
        onChange={handleInputChange}
      />
      <FormInput
        label="Accreditations (comma separated list)"
        name="accreditations"
        value={formData.accreditations}
        onChange={handleInputChange}
      />
      <FormInput
        label="Methodologies (comma separated list)"
        name="methodologies"
        value={formData.methodologies}
        onChange={handleInputChange}
      />
      <FormInput
        label="Associations (comma separated list)"
        name="associations"
        value={formData.associations}
        onChange={handleInputChange}
      />
      <CustomButton type="submit">Find users</CustomButton>
    </Container>
  );
}

export default Form;
