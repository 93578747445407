import React, { useReducer } from "react";
import axios from "axios";

import authReducer from "./authReducer";
import {
  GET_ME,
  AUTHENTICATE_USER,
  DEAUTHENTICATE_USER,
  AUTH_ERROR,
  ERROR_WITH_MESSAGE,
  CLEAR_ERRORS
} from "../types";
import setAuthToken from "../../util/set-auth-token";
import AuthContext from "./authContext";

function AuthState({ children }) {
  const initialState = {
    token: localStorage.getItem("token"),
    user: null,
    isLoading: true,
    errors: []
  };

  const [state, dispatch] = useReducer(authReducer, initialState);

  async function getMe() {
    setAuthToken(localStorage.token);

    try {
      const response = await axios("/api/v1/auth/me");

      dispatch({ type: GET_ME, payload: response.data });
    } catch (err) {
      dispatch({ type: AUTH_ERROR });
    }
  }

  async function signUp(formData) {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    try {
      const res = await axios.post("/api/v1/auth/sign-up", formData, config);

      dispatch({ type: AUTHENTICATE_USER, payload: res.data });

      getMe();
    } catch (err) {
      if (err.response.data.error) {
        const errors = err.response.data.error.split(",");

        dispatch({ type: ERROR_WITH_MESSAGE, payload: errors });
      }
    }
  }

  async function signIn(formData) {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    try {
      const res = await axios.post("/api/v1/auth/sign-in", formData, config);

      dispatch({ type: AUTHENTICATE_USER, payload: res.data });

      getMe();
    } catch (err) {
      if (err.response.data.error) {
        const errors = err.response.data.error.split(",");

        dispatch({ type: ERROR_WITH_MESSAGE, payload: errors });
      }
    }
  }

  async function updateDetails(formData) {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    try {
      const res = await axios.put(
        "/api/v1/auth/update-details",
        formData,
        config
      );

      dispatch({ type: GET_ME, payload: res.data });
    } catch (err) {
      if (err.response.data.error) {
        const errors = err.response.data.error.split(",");

        dispatch({ type: ERROR_WITH_MESSAGE, payload: errors });
      }
    }
  }

  function signOut() {
    dispatch({ type: DEAUTHENTICATE_USER });
  }

  const clearErrors = () => dispatch({ type: CLEAR_ERRORS });

  return (
    <AuthContext.Provider
      value={{
        token: state.token,
        user: state.user,
        isLoading: state.isLoading,
        errors: state.errors,
        getMe,
        signUp,
        signIn,
        updateDetails,
        clearErrors,
        signOut
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export default AuthState;
