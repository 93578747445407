import React from "react";
import styled from "styled-components";

import CustomFaIcon from "../custom-fa-icon";

const Container = styled.div`
  display: flex;
`;

const Heading = styled.h3`
  margin-bottom: 8px;
  font-weight: 300;
  color: white;
`;

const TextContainer = styled.div`
  padding-left: 16px;
  font-weight: 300;
  color: white;
`;

function Item({ faClassName, headingText, paragraphText }) {
  return (
    <Container>
      <CustomFaIcon faClassName={faClassName} />
      <TextContainer>
        <Heading>{headingText}</Heading>
        <p>{paragraphText}</p>
      </TextContainer>
    </Container>
  );
}

export default Item;
