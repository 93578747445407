import { GET_USERS, CLEAR_ERRORS } from "../types";

export default function(state, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_USERS:
      return { ...state, users: payload };
    case CLEAR_ERRORS:
      return { ...state, errors: [] };
    default:
      return state;
  }
}
