import React from "react";
import PropTypes from "prop-types";

import Item from "./Item";

function List({ items }) {
  const itemsMarkup = items.map((item, index) => (
    <Item key={index} {...item} />
  ));

  return <ul className="fa-ul">{itemsMarkup}</ul>;
}

List.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      faClassName: PropTypes.string,
      headingText: PropTypes.string
    })
  )
};

export default List;
