import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider, createGlobalStyle } from "styled-components";

import AuthState from "./context/auth/AuthState";
import UsersState from "./context/users/UsersState";
import AlertState from "./context/alert/AlertState";

import App from "./App";

const theme = {
  fontStack: "'Roboto', sans-serif",
  xsP: "36px",
  textShadow: "0px 2px 3px hsla(0, 0%, 0%, 0.3)",
  boxShadow1: "0 1px 3px rgba(0, 0, 0, .12), 0 1px 2px rgba(0, 0, 0, .24)",
  boxShadow2: "0 3px 6px rgba(0, 0, 0, .15), 0 2px 4px rgba(0, 0, 0, .12)",
  boxShadow3: "0 10px 20px rgba(0, 0, 0, .15), 0 3px 6px rgba(0, 0, 0, .10)",
  boxShadow4: "0 15px 25px rgba(0, 0, 0, .15), 0 5px 10px rgba(0, 0, 0, .05)",
  boxShadow5: "0 20px 40px rgba(0, 0, 0, .2)",
  color1: "hsl(212, 33%, 98%)",
  color2: "hsl(210, 31%, 80%)",
  color3: "hsl(211, 27%, 70%)",
  color4: "hsl(209, 23%, 60%)",
  color5: "hsl(210, 22%, 49%)",
  color6: "hsl(209, 28%, 39%)",
  color7: "hsl(209, 34%, 30%)",
  color8: "hsl(211, 39%, 23%)",
  color9: "hsl(209, 61%, 16%)"
};

const GlobalStyle = createGlobalStyle`
    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }

    body {
        font-family: ${props => props.theme.fontStack};
    }

    h1,
    h2,
    h3 {
        font-weight: 400;
        line-height: 1;
    }

    p {
        line-height: 1.5;
    }

    a {
        text-decoration: none;
        color: black;
    }

    .mb-1 {
        margin-bottom: 4px;
    }

    .mb-2 {
        margin-bottom: 8px;
    }

    .mb-3 {
        margin-bottom: 12px;
    }

    .mb-4 {
        margin-bottom: 16px;
    }

    .mb-5 {
        margin-bottom: 20px;
    }

    .mb-6 {
        margin-bottom: 24px;
    }

    .mb-7 {
        margin-bottom: 28px;
    }

    .mb-8 {
        margin-bottom: 32px;
    }
`;

ReactDOM.render(
  <AuthState>
    <UsersState>
      <AlertState>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <GlobalStyle />
            <App />
          </ThemeProvider>
        </BrowserRouter>
      </AlertState>
    </UsersState>
  </AuthState>,
  document.getElementById("root")
);
