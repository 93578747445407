import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Container = styled.i`
  cursor: pointer;
`;

function NavButton({ handleClick }) {
  return <Container className="fas fa-bars" onClick={handleClick}></Container>;
}

NavButton.propTypes = {
  handleClick: PropTypes.func.isRequired
};

export default NavButton;
