import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";

import AuthContext from "../../context/auth/authContext";

import NavButton from "./NavButton";
import Nav from "./Nav";

const Container = styled.header`
  padding: 16px ${props => props.theme.xsP};
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 98;
  box-shadow: ${props => props.theme.boxShadow1};
`;

function Header() {
  const authContext = useContext(AuthContext);

  const [showNav, setShowNav] = useState(false);

  useEffect(() => {
    authContext.getMe();

    // eslint-disable-next-line
  }, []);

  function toggleNav() {
    setShowNav(!showNav);
  }

  return (
    <Container>
      <NavButton handleClick={toggleNav} />
      <Nav
        showNav={showNav}
        dismiss={toggleNav}
        user={authContext.user}
        signOut={authContext.signOut}
      />
    </Container>
  );
}

export default Header;
