import React from "react";
import styled from "styled-components";

const Icon = styled.i`
  color: white;
`;

function CustomFaIcon({ faClassName }) {
  return <Icon className={faClassName} />;
}

export default CustomFaIcon;
