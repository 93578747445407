import React, { useContext, useState } from "react";
import axios from "axios";
import styled, { css } from "styled-components";

import AlertContext from "../../context/alert/alertContext";

import FormInput from "../form-input";
import CustomButton from "../custom-button";

const Container = styled.div`
  padding: ${props => props.theme.xsP};
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Heading = styled.h2`
  margin-bottom: 24px;
  font-weight: 300;
`;

const subColor = "grey";
const mainColor = "black";

const shrinkLabelStyles = css`
  top: -14px;
  font-size: 12px;
  color: ${mainColor};
`;

const Paragraph = styled.p`
  margin-bottom: 32px;
`;

const Link = styled.a`
  font-weight: 700;
`;

const GroupContainer = styled.div`
  position: relative;
  margin: 45px 0;
  input[type="password"] {
    letter-spacing: 0.3em;
  }
  &:first-child {
    margin-top: 0;
  }
`;

const Textarea = styled.textarea`
  background: none;
  background-color: white;
  color: ${subColor};
  font-size: 18px;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid ${subColor};
  margin: 25px 0;
  &:focus {
    outline: none;
  }
  &:focus ~ label {
    ${shrinkLabelStyles}
  }
`;

const Label = styled.label`
  color: ${subColor};
  font-size: 16px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 300ms ease all;
  &.shrink {
    ${shrinkLabelStyles}
  }
`;

function Contact() {
  const alertContext = useContext(AlertContext);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: ""
  });

  async function handleSubmit(event) {
    event.preventDefault();

    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    try {
      await axios.post("/api/v1/contact", formData, config);

      alertContext.setAlert("Message sent!", "success");
    } catch (err) {
      alertContext.setAlert(err.response.data.error, "danger");
    }
  }

  function handleChange(event) {
    const { name, value } = event.target;

    setFormData({ ...formData, [name]: value });
  }

  return (
    <Container id="contact">
      <Heading>Get in Touch</Heading>
      <Paragraph>Drop me a line in the contact form or call me on <Link href="tel:01628566112">01628 566112</Link></Paragraph>
      <form onSubmit={handleSubmit}>
        <FormInput
          label="Name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          autoComplete="name"
        />
        <FormInput
          label="Email"
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          autoComplete="email"
        />
        <GroupContainer>
          <Textarea
            name="message"
            value={formData.message}
            onChange={handleChange}
            rows="1"
          />
          <Label className={formData.message.length && "shrink"}>Message</Label>
        </GroupContainer>
        <CustomButton type="submit">Send</CustomButton>
      </form>
    </Container>
  );
}

export default Contact;
